import { useAtom } from 'jotai';
import { addDesignModalAtoms } from '../../../add-design-modal.atoms';
import { lightCategories } from '../../../add-design-modal.constants';
import { AddDesignModalContentTiles } from '../../add-design-modal-content-tiles';
import { ContentTileItemDefaultDef } from '../../add-design-modal-content-tiles/add-design-modal-content-tiles.types';

export const AddDesignModalCategoryStepContent = () => {
  const [category, setCategory] = useAtom(addDesignModalAtoms.category);

  const items = [
    lightCategories.tableLight,
    lightCategories.wallLight,
    lightCategories.floorLight,
  ] satisfies ContentTileItemDefaultDef[];

  return (
    <AddDesignModalContentTiles
      getIsActive={({ name }) => {
        return name === category;
      }}
      getThumbnailSrc={({ isActive, name }) => {
        const status = isActive ? 'on' : 'off';
        const categoryKey = name.split(' ')?.[0]?.toLowerCase();

        return `https://res.cloudinary.com/gantri/image/upload/static-assets/graphite/design-flow/categories/${categoryKey}-task-${status}_2x.png`;
      }}
      items={items}
      onSetActiveItem={(item) => {
        setCategory(item.name);
      }}
    />
  );
};
