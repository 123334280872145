import { ContentTileItemDefaultDef } from './components/add-design-modal-content-tiles/add-design-modal-content-tiles.types';

export const addDesignModalSteps = {
  category: 'LIGHT_CATEGORY',
  name: 'LIGHT_NAME',
  size: 'LIGHT_SIZE',
  subCategory: 'LIGHT_SUBCATEGORY',
  type: 'DESIGN_TYPE',
} as const;

export const lightCategories = {
  floorLight: {
    description: 'Intended to stand on a floor.',
    name: 'Floor Light',
  },
  tableLight: {
    description: 'Intended to sit on a table.',
    name: 'Table Light',
  },
  wallLight: {
    description: 'Intended to be installed on a wall.',
    name: 'Wall Light',
  },
} as const satisfies Record<string, ContentTileItemDefaultDef>;

export const lightSubCategories = {
  accent: {
    description: 'Decorative, diffusion is limited',
    name: 'Accent',
  },
  area: {
    description: 'Used as the main light source',
    name: 'Area',
  },
  task: {
    description: 'A directional light, for more focus.',
    name: 'Task',
  },
} as const satisfies Record<string, ContentTileItemDefaultDef>;

export const lightSizeDetails = {
  compact: {
    code: 'cm',
    description: '' as string,
    label: 'C',
    name: 'Compact',
  },
  large: {
    code: 'lg',
    description: '' as string,
    label: 'L',
    name: 'Large',
  },
  medium: {
    code: 'md',
    description: '' as string,
    label: 'M',
    name: 'Medium',
  },
  small: {
    code: 'sm',
    description: '' as string,
    label: 'S',
    name: 'Small',
  },
} as const satisfies Record<string, ContentTileItemDefaultDef>;
