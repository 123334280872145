import { useAtomValue } from 'jotai';
import { AddDesignModalTypeStepContent } from '../step-type/add-design-modal-type-step-content';
import { AddDesignModalCategoryStepContent } from '../step-category/add-design-modal-category-step-content';
import { AddDesignModalSubCategoryStepContent } from '../step-subcategory/add-design-modal-sub-category-step-content';
import { AddDesignModalSizeStepContent } from '../step-size/add-design-modal-size-step-content';
import { AddDesignModalNameStepContent } from '../step-name/add-design-modal-name-step-content';
import { addDesignModalAtoms } from '../../add-design-modal.atoms';
import { addDesignModalSteps } from '../../add-design-modal.constants';

export const AddDesignModalContent = () => {
  const step = useAtomValue(addDesignModalAtoms.step);

  switch (step) {
    case addDesignModalSteps.type:
      return <AddDesignModalTypeStepContent />;

    case addDesignModalSteps.category:
      return <AddDesignModalCategoryStepContent />;

    case addDesignModalSteps.subCategory:
      return <AddDesignModalSubCategoryStepContent />;

    case addDesignModalSteps.size:
      return <AddDesignModalSizeStepContent />;

    case addDesignModalSteps.name:
      return <AddDesignModalNameStepContent />;

    default:
      return null;
  }
};
