import { useAtom, useAtomValue } from 'jotai';
import { addDesignModalAtoms } from '../../../add-design-modal.atoms';
import { lightSubCategories } from '../../../add-design-modal.constants';
import { AddDesignModalContentTiles } from '../../add-design-modal-content-tiles';
import { ContentTileItemDefaultDef } from '../../add-design-modal-content-tiles/add-design-modal-content-tiles.types';

export const AddDesignModalSubCategoryStepContent = () => {
  const category = useAtomValue(addDesignModalAtoms.category);
  const [subCategory, setSubCategory] = useAtom(
    addDesignModalAtoms.subCategory,
  );

  const items = [
    lightSubCategories.accent,
    lightSubCategories.area,
    lightSubCategories.task,
  ] satisfies ContentTileItemDefaultDef[];

  return (
    <AddDesignModalContentTiles
      getIsActive={({ name }) => {
        return name === subCategory;
      }}
      getThumbnailSrc={({ isActive, name }) => {
        const status = isActive ? 'on' : 'off';
        const categoryKey = category.split(' ')?.[0]?.toLowerCase();

        return `https://res.cloudinary.com/gantri/image/upload/static-assets/graphite/design-flow/subcategories/${categoryKey}-${name.toLowerCase()}-${status}_2x.png`;
      }}
      items={items}
      onSetActiveItem={(item) => {
        setSubCategory(item.name);
      }}
    />
  );
};
