import { FC } from 'react';
import { LayoutProvider } from 'context/layout.context';
import { NotificationsProvider } from 'context/notification.context';
import { AuthProvider } from 'context/auth.context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { lightTheme, ThemeProvider } from 'gantri-components';
import { ReactQueryProvider } from './app-react-query';

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 100000 } },
});

export const AppProviders: FC = ({ children }) => {
  return (
    <ReactQueryProvider>
      <ThemeProvider theme={lightTheme}>
        <QueryClientProvider client={queryClient}>
          <LayoutProvider>
            <NotificationsProvider>
              <AuthProvider>{children}</AuthProvider>
            </NotificationsProvider>
          </LayoutProvider>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </ReactQueryProvider>
  );
};
