import { useAtomValue } from 'jotai';
import { addDesignModalAtoms } from '../../add-design-modal.atoms';
import { addDesignModalSteps } from '../../add-design-modal.constants';
import { AddDesignModalFooterPropsDef } from './add-design-modal-footer.types';
import { AddDesignModalTypeStepFooter } from '../step-type/add-design-modal-type-step-footer';
import { AddDesignModalCategoryStepFooter } from '../step-category/add-design-modal-category-step-footer';
import { AddDesignModalSubCategoryStepFooter } from '../step-subcategory/add-design-modal-sub-category-step-footer';
import { AddDesignModalSizeStepFooter } from '../step-size/add-design-modal-size-step-footer';
import { AddDesignModalNameStepFooter } from '../step-name/add-design-modal-name-step-footer';

export const AddDesignModalFooter = (props: AddDesignModalFooterPropsDef) => {
  const { onClose } = props;

  const step = useAtomValue(addDesignModalAtoms.step);

  switch (step) {
    case addDesignModalSteps.type:
      return <AddDesignModalTypeStepFooter onClose={onClose} />;

    case addDesignModalSteps.category:
      return <AddDesignModalCategoryStepFooter />;

    case addDesignModalSteps.subCategory:
      return <AddDesignModalSubCategoryStepFooter />;

    case addDesignModalSteps.size:
      return <AddDesignModalSizeStepFooter />;

    case addDesignModalSteps.name:
      return <AddDesignModalNameStepFooter onClose={onClose} />;

    default:
      return null;
  }
};
