import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import './assets/fonts/univers-55-roman.woff';
import './assets/fonts/univers-55-roman.woff2';
import './assets/fonts/univers-65-bold.woff';
import './assets/fonts/univers-65-bold.woff2';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
