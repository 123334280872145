import { Typography } from 'gantri-components';
import { useMemo } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { addDesignModalAtoms } from '../../../add-design-modal.atoms';
import {
  lightCategories,
  lightSizeDetails,
} from '../../../add-design-modal.constants';
import { AddDesignModalContentTiles } from '../../add-design-modal-content-tiles';
import { ContentTileItemDefaultDef } from '../../add-design-modal-content-tiles/add-design-modal-content-tiles.types';
import { StyledIconStack } from './add-design-modal-size-step-content.styles';

export const AddDesignModalSizeStepContent = () => {
  const category = useAtomValue(addDesignModalAtoms.category);
  const [sizeDetails, setSizeDetails] = useAtom(
    addDesignModalAtoms.sizeDetails,
  );

  const items = useMemo(() => {
    switch (category) {
      case lightCategories.floorLight.name:
        return [
          {
            ...lightSizeDetails.small,
            description: '40-50” tall',
          },
          {
            ...lightSizeDetails.medium,
            description: '50-60" tall',
          },
          {
            ...lightSizeDetails.large,
            description: '60-70” tall',
          },
        ] satisfies ContentTileItemDefaultDef[];

      case lightCategories.tableLight.name:
        return [
          {
            ...lightSizeDetails.compact,
            description: 'Up to 10" tall. A sculptural accent.',
          },
          {
            ...lightSizeDetails.small,
            description: '10-14" tall. Best for small spaces.',
          },
          {
            ...lightSizeDetails.medium,
            description: '14-18" tall. The standard size.',
          },
          {
            ...lightSizeDetails.large,
            description: '18-22" tall. A statement piece.',
          },
        ] satisfies ContentTileItemDefaultDef[];

      case lightCategories.wallLight.name:
        return [
          {
            ...lightSizeDetails.small,
            description: '< 8”D x 10”W x 10”H',
          },
          {
            ...lightSizeDetails.large,
            description: '12”D x 15”W x 15”H',
          },
        ] satisfies ContentTileItemDefaultDef[];

      default:
        return [];
    }
  }, [category]);

  return (
    <AddDesignModalContentTiles
      getIsActive={({ name }) => {
        return name === sizeDetails?.name;
      }}
      ItemIcon={(props) => {
        const { isActive, label } = props;

        return (
          <StyledIconStack
            $isActive={isActive}
            alignContent="center"
            height="6rem"
            justifyContent="center"
            padding="x"
            width="6rem"
          >
            <Typography color="alt" text={label} variant="h4" />
          </StyledIconStack>
        );
      }}
      items={items}
      onSetActiveItem={setSizeDetails}
    />
  );
};
