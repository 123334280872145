import { FC } from 'react';
import { Page } from 'core/page';
import { Typography } from 'core/typography';

export const PayoutsPage: FC = () => {
  return (
    <Page>
      <Typography text="Under Construction" variant="h2" />
    </Page>
  );
};
