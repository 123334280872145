import { Button } from 'gantri-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { addDesignModalAtoms } from '../../../add-design-modal.atoms';
import { addDesignModalSteps } from '../../../add-design-modal.constants';
import { AddDesignModalTypeStepFooterPropsDef } from './add-design-modal-type-step-footer.types';

export const AddDesignModalTypeStepFooter = (
  props: AddDesignModalTypeStepFooterPropsDef,
) => {
  const { onClose } = props;

  const setStep = useSetAtom(addDesignModalAtoms.step);
  const category = useAtomValue(addDesignModalAtoms.category);

  const isDisabled = !category;

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onClose}
      />
      <Button
        disabled={isDisabled}
        size="large"
        text="Next"
        onClick={() => {
          setStep(addDesignModalSteps.category);
        }}
      />
    </>
  );
};
