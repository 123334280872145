import { Button } from 'gantri-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { addDesignModalAtoms } from '../../../add-design-modal.atoms';
import { addDesignModalSteps } from '../../../add-design-modal.constants';

export const AddDesignModalCategoryStepFooter = () => {
  const setStep = useSetAtom(addDesignModalAtoms.step);
  const category = useAtomValue(addDesignModalAtoms.category);

  const isDisabled = !category;

  return (
    <>
      <Button
        size="large"
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep(addDesignModalSteps.type);
        }}
      />
      <Button
        disabled={isDisabled}
        size="large"
        text="Next"
        onClick={() => {
          setStep(addDesignModalSteps.subCategory);
        }}
      />
    </>
  );
};
