import { atom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { useEffect } from 'react';

export type PrimitiveAtomWithInitialValueDef<T extends any> = ReturnType<
  typeof atom<T>
>;

export const useResetAtom = (atom: PrimitiveAtomWithInitialValueDef<any>) => {
  const initialValue = atom.init;

  return useAtomCallback((_get, set) => {
    set(atom, initialValue);
  });
};

export const useResetAllAtoms = (
  atoms: Record<string, PrimitiveAtomWithInitialValueDef<any>>,
) => {
  const atomsArray = Object.values(atoms);

  return useAtomCallback((_get, set) => {
    atomsArray?.forEach((atom) => {
      const initialValue = atom.init;

      set(atom, initialValue);
    });
  });
};

export const useResetAllAtomsOnUnmount = (
  atoms: Record<string, PrimitiveAtomWithInitialValueDef<any>>,
) => {
  const resetAllAtoms = useResetAllAtoms(atoms);

  useEffect(() => {
    return resetAllAtoms;
  }, []);
};

export const useResetAllAtomsOnMount = (
  atoms: Record<string, PrimitiveAtomWithInitialValueDef<any>>,
) => {
  const resetAllAtoms = useResetAllAtoms(atoms);

  useEffect(() => {
    resetAllAtoms();
  }, []);
};
