import { atom } from 'jotai';
import { PrimitiveAtomWithInitialValueDef } from 'utils/hooks/use-reset-atoms';
import {
  AddDesignModalStep,
  LightCategory,
  LightSizeDetails,
  LightSubCategory,
} from './add-design-modal.types';
import {
  addDesignModalSteps,
  lightCategories,
} from './add-design-modal.constants';

export const addDesignModalAtoms = {
  category: atom<LightCategory>(lightCategories.tableLight.name),
  name: atom<string>(''),
  // ? using 'as' assertion due to https://github.com/pmndrs/jotai/issues/550
  sizeDetails: atom<LightSizeDetails>(
    null,
  ) as PrimitiveAtomWithInitialValueDef<LightSizeDetails>,
  step: atom<AddDesignModalStep>(addDesignModalSteps.category),
  // ? using 'as' assertion due to https://github.com/pmndrs/jotai/issues/550
  subCategory: atom<LightSubCategory>(
    null,
  ) as PrimitiveAtomWithInitialValueDef<LightSubCategory>,
  type: atom<string>('light'),
};
